import { defaultLenders, UGASecondary, UGAT, myMILAN } from './lenders.ts';
import { updateLenderRate } from './createLender.ts';

export const STATES = [
  {
    name: 'Select State',
    cohort: undefined,
    lenders: [...defaultLenders],
  },
  {
    name: 'Arizona',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Arkansas',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.17, max: 0.17 }),
      updateLenderRate(UGAT, { min: 0.17, max: 0.17 }),
      updateLenderRate(myMILAN, { min: 0.17, max: 0.17 }),
    ],
  },
  {
    name: 'Colorado',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.21, max: 0.21 }),
      updateLenderRate(UGAT, { min: 0.21, max: 0.21 }),
      updateLenderRate(myMILAN, { min: 0.21, max: 0.21 }),
    ],
  },
  {
    name: 'Connecticut',
    cohort: 3,
    lenders: [...defaultLenders, UGASecondary, UGAT, myMILAN],
  },
  {
    name: 'Florida',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      UGASecondary,
      UGAT,
      updateLenderRate(myMILAN, { min: 0.12, max: 0.12 }),
    ],
  },
  {
    name: 'Georgia',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.199, max: 0.199 }),
      updateLenderRate(UGAT, { min: 0.199, max: 0.199 }),
      updateLenderRate(myMILAN, { min: 0.13, max: 0.13 }),
    ],
  },
  {
    name: 'Idaho',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Illinois',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Indiana',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.21, max: 0.21 }),
      updateLenderRate(UGAT, { min: 0.21, max: 0.21 }),
      myMILAN,
    ],
  },
  {
    name: 'Iowa',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.21, max: 0.21 }),
      updateLenderRate(UGAT, { min: 0.21, max: 0.21 }),
      updateLenderRate(myMILAN, { min: 0.21, max: 0.21 }),
    ],
  },
  {
    name: 'Kansas',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Kentucky',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Louisiana',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      UGASecondary,
      UGAT,
      updateLenderRate(myMILAN, { min: 0.18, max: 0.18 }),
    ],
  },
  {
    name: 'Maine',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      UGASecondary,
      UGAT,
      updateLenderRate(myMILAN, { min: 0.18, max: 0.18 }),
    ],
  },
  {
    name: 'Maryland',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.24, max: 0.24 }),
      updateLenderRate(UGAT, { min: 0.24, max: 0.24 }),
      updateLenderRate(myMILAN, { min: 0.24, max: 0.24 }),
    ],
  },
  {
    name: 'Massachusetts',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      UGASecondary,
      UGAT,
      updateLenderRate(myMILAN, { min: 0.21, max: 0.21 }),
    ],
  },
  {
    name: 'Michigan',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Minnesota',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.19, max: 0.19 }),
      updateLenderRate(UGAT, { min: 0.19, max: 0.19 }),
      updateLenderRate(myMILAN, { min: 0.19, max: 0.19 }),
    ],
  },
  {
    name: 'Missouri',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Montana',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Nebraska',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      UGASecondary,
      UGAT,
      updateLenderRate(myMILAN, { min: 0.18, max: 0.18 }),
    ],
  },
  {
    name: 'Nevada',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'New Hampshire',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'New Mexico',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'New York',
    cohort: 3,
    lenders: [...defaultLenders, UGASecondary, UGAT, myMILAN],
  },
  {
    name: 'North Carolina',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      UGASecondary,
      UGAT,
      updateLenderRate(myMILAN, { min: 0.18, max: 0.18 }),
    ],
  },
  {
    name: 'North Dakota',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(myMILAN, { min: 0.07, max: 0.07 }),
    ],
  },
  {
    name: 'Ohio',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Oklahoma',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.21, max: 0.21 }),
      updateLenderRate(UGAT, { min: 0.21, max: 0.21 }),
      updateLenderRate(myMILAN, { min: 0.21, max: 0.21 }),
    ],
  },
  {
    name: 'Oregon',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Pennsylvania',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'South Dakota',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Tennessee',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.21, max: 0.21 }),
      updateLenderRate(UGAT, { min: 0.21, max: 0.21 }),
      updateLenderRate(myMILAN, { min: 0.1175, max: 0.1175 }),
    ],
  },
  {
    name: 'Texas',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.199, max: 0.199 }),
      updateLenderRate(UGAT, { min: 0.199, max: 0.199 }),
      updateLenderRate(myMILAN, { min: 0.18, max: 0.18 }),
    ],
  },
  {
    name: 'Utah',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Virginia',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Washington',
    cohort: 3,
    lenders: [
      ...defaultLenders,
      updateLenderRate(UGASecondary, { min: 0.2499, max: 0.2499 }),
      updateLenderRate(UGAT, { min: 0.2499, max: 0.2499 }),
      myMILAN,
    ],
  },
  {
    name: 'Wisconsin',
    cohort: 3,
    lenders: [...defaultLenders, UGASecondary, UGAT, myMILAN],
  },
];
